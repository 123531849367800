<template>
  <!-- 面试淘汰 -->
  <div class="viewEli">
    <van-nav-bar
      :title="title"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-field
      readonly
      clickable
      class="reason"
      name="picker"
      :value="value"
      :label="labelName"
      placeholder="点击选择"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-cell value="备注" />
    <van-field
      v-model="message"
      rows="2"
      class="remark"
      autosize
      type="textarea"
      maxlength="500"
      placeholder="请输入"
      show-word-limit
    />

    <van-button type="info">确定</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      labelName: "淘汰原因",
      show: false,
      value: "",
      columns: ["技能不符岗位要求", "面试者个人原因", "薪资要求太高", "其他"],
      showPicker: false,
      message: "",
      leftToPath: "",
    };
  },
  mounted() {
    this.title = this.$route.query.title;
    if (this.title === "放弃入职") {
      this.labelName = "放弃原因";
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.leftToPath = from.fullPath;
    });
  },

  methods: {
    onClickLeft() {
      this.$router.push(this.leftToPath);
    },
    onClickRight() {},
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.viewEli {
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  height: 100vh;
  text-align: center;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .reason {
    height: 106px;

    margin-bottom: 24px;
    /deep/.van-cell__title {
      width: 5em;
      line-height: 65px;
      span::before {
        content: "*";
        color: red;
      }
    }

    /deep/ .van-cell__value {
      line-height: 65px;
    }
  }
  .remark {
    height: 698px;
    margin-bottom: 48px;
    /deep/.van-cell__title {
      width: 2em;
    }
    /deep/.van-cell__value {
      .van-field__body {
        align-items: flex-start;
        height: calc(100% - 32px);
        /deep/ textarea {
          height: 100% !important;
        }
      }
    }
  }
  .van-button {
    width: 90%;
    height: 88px;

    background: #2b8df0;
  }
}
</style>
